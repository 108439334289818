<template lang="pug">
  .calendar
    .competitors-definition-wrapper
      CompetitorDefinition(
        :competitors="competitors"
        :rental-periods="rentalPeriods"
      )
    ScrollSync.competitors-data-wrapper(
      v-if="!isEmpty(competitors)"
      horizontal
    )
      CompetitorsData(
        :competitors="competitors"
        :rental-periods="rentalPeriods"
        :date-range="dateRange"
      )
</template>

<script>
  import { isEmpty } from "lodash-es"

  export default {
    components: {
      CompetitorDefinition: () => import("./CompetitorDefinition"),
      CompetitorsData: () => import("./CompetitorsData")
    },

    props: {
      competitors: {
        type: Array,
        default: () => new Array()
      },
      rentalPeriods: {
        type: Array,
        default: () => new Array()
      },
      dateRange: {
        type: Array,
        default: () => new Array()
      }
    },

    methods: {
      isEmpty
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .calendar
    align-items: flex-start
    display: flex

    .competitors-data-wrapper
      border-right: 1px solid $default-purple-light-line
      overflow-x: auto
</style>
